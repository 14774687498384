import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SharedService } from './services/shared.service';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from './../api-authorization/api-authorization.module';
import { AuthorizeGuard } from './../api-authorization/authorize.guard';
import { AuthorizeInterceptor } from './../api-authorization/authorize.interceptor';
import { ErrorInterceptor } from './interceptor/error-interceptor';

import { IncidentReportComponent } from './incident-report/incident-report.component';
import { IncidentReportCreateComponent } from './incident-report/create/create.component';
import { IncidentReportReviewComponent } from './incident-report/review/review.component';
import { IncidentReportStatisticsComponent } from './incident-report/statistics/statistics.component';
import { EprescriberComponent } from './eprescriber/eprescriber.component';
import { EprescriberApprovalComponent } from './eprescriber/approval/approval.component';
import { EprescriberCreateComponent } from './eprescriber/create/create.component';
import { ReceivingComponent } from './receiving/receiving.component';
import { ReorderComponent } from './reorder/reorder.component';
import { ReorderCreateComponent } from './reorder/create/create.component';
import { ReorderViewComponent } from './reorder/view/view.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsFacilityComponent } from './settings/facility/facility.component';
import { SettingsUsersComponent } from './settings/users/users.component';
import { SettingsUserCreateComponent } from './settings/users/create/create.component';
import { SettingsUserProfileComponent } from './settings/users/profile/profile.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ShippingCreateComponent } from './shipping/create/create.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { PrescriptionReviewComponent } from './prescription-review/prescription-review.component';
import { PrescriptionReviewReviewComponent } from './prescription-review/review/review.component';

import { NavMenuPharmacyComponent } from './nav-menu/pharmacy/pharmacy.component';
import { DrugRecordBookComponent } from './drug-record-book/drug-record-book.component';

import { ReactiveFormsModule } from '@angular/forms';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';

//Angular Material Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';


import { UploadComponent } from './shared-components/upload/upload.component';
import { CreateReorderPrescriptionComponent } from './reorder/create/create-reorder-prescription/create-reorder-prescription.component';
import { CreateReorderCustomComponent } from './reorder/create/create-reorder-custom/create-reorder-custom.component';
import { HighlightPipe } from './home/home.component';
import { FacilityUnitComponent } from './shared-components/facility-unit/facility-unit.component';
import { PharmacistReviewComponent } from './reorder/pharmacist-review/pharmacist-review.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HeaderComponent } from './shared-components/header/header.component';
import { SuccessMessageComponent } from './shared-components/success-message/success-message.component';
import { AnnualComponent } from './medscheck/annual/annual.component';
import { MedicationSeachFieldComponent } from './shared-components/medication-seach-field/medication-seach-field.component';
import { AnnualLtcComponent } from './medscheck/annual-ltc/annual-ltc.component';
import { AnnualHomeComponent } from './medscheck/annual-home/annual-home.component';
import { AnnualDiabetesComponent } from './medscheck/annual-diabetes/annual-diabetes.component';
import { SignaturePadComponent } from './shared-components/signature-pad/signature-pad.component';

import { SignaturePadModule } from 'angular2-signaturepad';
import { NarcoticReconciliationComponent } from './narcotic-reconciliation/narcotic-reconciliation.component';
import { PatientComponent } from './patient/patient.component';
import { PatientProfileComponent } from './patient/profile/profile.component';
import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DrugComponent } from './settings/drug/drug.component';
import { InitialCountComponent } from './narcotic-reconciliation/initial-count/initial-count.component';
import { NarcoticReconciliationItemComponent } from './narcotic-reconciliation/narcotic-reconciliation-item/narcotic-reconciliation-item.component';
import { NarcoticReconciliationItemDetailComponent } from './narcotic-reconciliation/narcotic-reconciliation-item-detail/narcotic-reconciliation-item-detail.component';
import { NarcoticReconciliationPurchaseItemComponent } from './narcotic-reconciliation/narcotic-reconciliation-purchase-item/narcotic-reconciliation-purchase-item.component';
import { NarcoticReconciliationPurchaseOthersComponent } from './narcotic-reconciliation/narcotic-reconciliation-purchase-others/narcotic-reconciliation-purchase-others.component';
import { NarcoticReconciliationDestructionItemComponent } from './narcotic-reconciliation/narcotic-reconciliation-destruction-item/narcotic-reconciliation-destruction-item.component';
import { SalesPaymentComponent } from './pos/reports/sales-payment/sales-payment.component';
import { SettingsTrainingComponent } from './settings/training/training.component';
import { SettingsTrainingCreateComponent, } from './settings/training/training-create/training-create.component';
import { UserTrainingComponent } from './user-training/user-training.component';
import { UserTrainingItemComponent } from './user-training/user-training-item/user-training-item.component';
import { UserTrainingAssessmentComponent } from './user-training/user-training-assessment/user-training-assessment.component';
import { ReportTrainingComponent } from './reports/report-training/report-training.component';
import { PosTokenComponent } from './pos/token/token.component';
import { GeneralInformationComponent } from './medscheck/annual-diabetes/general-information/general-information.component';
import { GeneralDiabetesEducationComponent } from './medscheck/annual-diabetes/general-diabetes-education/general-diabetes-education.component';
import { SelfMonitoringBloodGlucoseComponent } from './medscheck/annual-diabetes/self-monitoring-blood-glucose/self-monitoring-blood-glucose.component';
import { SpecialtyTrainingComponent } from './medscheck/annual-diabetes/specialty-training/specialty-training.component';
import { ResourcesProvidedComponent } from './medscheck/annual-diabetes/resources-provided/resources-provided.component';
import { ReferralsReasonComponent } from './medscheck/annual-diabetes/referrals-reason/referrals-reason.component';
import { SummaryGoalsComponent } from './medscheck/annual-diabetes/summary-goals/summary-goals.component';
import { PatientInformationComponent } from './shared-components/medscheck/patient-information/patient-information.component';
import { PatientCaregiverComponent } from './shared-components/medscheck/patient-caregiver/patient-caregiver.component';
import { PatientPrimaryCareComponent } from './shared-components/medscheck/patient-primary-care/patient-primary-care.component';
import { SupportInformationComponent } from './shared-components/medscheck/support-information/support-information.component';
import { PatientAcknowledgementComponent } from './shared-components/medscheck/patient-acknowledgement/patient-acknowledgement.component';
import { PatientLifestyleComponent } from './shared-components/medscheck/patient-lifestyle/patient-lifestyle.component';
import { ClinicalNeedServicesComponent } from './shared-components/medscheck/clinical-need-services/clinical-need-services.component';
import { MedicationComponent } from './shared-components/medscheck/medication/medication.component';
import { TherapeuticIssuesComponent } from './shared-components/medscheck/therapeutic-issues/therapeutic-issues.component';
import { ChecklistCompletnessComponent } from './shared-components/medscheck/checklist-completness/checklist-completness.component';
import { FollowUpComponent } from './shared-components/medscheck/follow-up/follow-up.component';
import { SummaryErrorMessageComponent } from './shared-components/medscheck/summary-error-message/summary-error-message.component';
import { PosBackupComponent } from './pos/downloads/backup/backup.component';
import { FaqVideosComponent  } from './faq/videos/videos.component';
import { FaqFormsComponent } from './faq/forms/forms.component';
import { FaqPolicyProcedureComponent } from './faq/policy-procedure/policy-procedure.component';
import { FaqAuditsComponent } from './faq/audits/audits.component';
import { SettingsDoctorComponent } from './settings/doctor/doctor.component';
import { SettingsDoctorCreateComponent } from './settings/doctor/create/create.component';
import { EducationalsComponent } from './faq/educationals/educationals.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceCreateComponent } from './invoice/create/create.component';
import { InvoiceViewComponent } from './invoice/view/view.component';
import { InfoComponent } from './faq/info/info.component';
import { InvoicePatientListComponent } from './invoice/patient-list/patient-list.component';
import { TermsUseComponent } from './faq/solvoIt/terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './faq/solvoIt/privacy-policy/privacy-policy.component';
import { SolvoItComponent } from './faq/solvoIt/solvo-it.component';
import { AdditionalReportComponent } from './reports/additionalReport/additional-report.component';
import { EmergencyPharmacyComponent } from './patient/emergency-pharmacy/emergency-pharmacy.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    IncidentReportComponent,
    IncidentReportCreateComponent,
    IncidentReportReviewComponent,
    IncidentReportStatisticsComponent,
    EprescriberComponent,
    EprescriberApprovalComponent,
    EprescriberCreateComponent,
    ReceivingComponent,
    ReorderComponent,
    ReorderCreateComponent,
    ReorderViewComponent,
    SettingsComponent,
    SettingsFacilityComponent,
    SettingsUsersComponent,
    SettingsUserCreateComponent,
    SettingsUserProfileComponent,
    SettingsTrainingComponent,
    SettingsTrainingCreateComponent,
    ShippingComponent,
    ShippingCreateComponent,
    StatisticsComponent,
    PrescriptionReviewComponent,
    PrescriptionReviewReviewComponent,

    NavMenuPharmacyComponent,

    UploadComponent,
    DrugRecordBookComponent,
    HighlightPipe,
    CreateReorderPrescriptionComponent,
    CreateReorderCustomComponent,
    FacilityUnitComponent,
    PharmacistReviewComponent,
    HeaderComponent,
    SuccessMessageComponent,
    AnnualComponent,
    MedicationSeachFieldComponent,
    AnnualLtcComponent,
    AnnualHomeComponent,
    AnnualDiabetesComponent,
    SignaturePadComponent,
    PatientComponent,
    NarcoticReconciliationComponent,
    PatientProfileComponent,
    TextareaAutoresizeDirective,
    DrugComponent,
    InitialCountComponent,
    NarcoticReconciliationItemComponent,
    NarcoticReconciliationItemDetailComponent,
    NarcoticReconciliationPurchaseItemComponent,
    NarcoticReconciliationPurchaseOthersComponent,
    NarcoticReconciliationDestructionItemComponent,
    SalesPaymentComponent,
    UserTrainingComponent,
    UserTrainingItemComponent,
    UserTrainingAssessmentComponent,
    ReportTrainingComponent,
    PosTokenComponent,
    PosBackupComponent,
    PosTokenComponent,
    PatientInformationComponent,
    GeneralInformationComponent,
    GeneralDiabetesEducationComponent,
    SelfMonitoringBloodGlucoseComponent,
    SpecialtyTrainingComponent,
    ResourcesProvidedComponent,
    ReferralsReasonComponent,
    SummaryGoalsComponent,
    PatientCaregiverComponent,
    PatientPrimaryCareComponent,
    SupportInformationComponent,
    PatientAcknowledgementComponent,
    PatientLifestyleComponent,
    ClinicalNeedServicesComponent,
    MedicationComponent,
    TherapeuticIssuesComponent,
    ChecklistCompletnessComponent,
    FollowUpComponent,
    SummaryErrorMessageComponent,
    FaqVideosComponent ,
    FaqFormsComponent,
    FaqPolicyProcedureComponent,
    FaqAuditsComponent,
    SettingsDoctorComponent,
    SettingsDoctorCreateComponent,
    EducationalsComponent,
    InvoiceComponent,
    InvoiceCreateComponent,
    InfoComponent,
    InvoicePatientListComponent,
    InvoiceViewComponent,
    TermsUseComponent,
    PrivacyPolicyComponent,
    SolvoItComponent,
    AdditionalReportComponent,
    EmergencyPharmacyComponent
 
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,

    AngularDateTimePickerModule,

    BrowserAnimationsModule,
    BrowserModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatNativeDateModule,
    DragDropModule,

    PdfViewerModule, 

    NgbModule,
   
    ReactiveFormsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ApiAuthorizationModule,
    SignaturePadModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent, canActivate: [AuthorizeGuard] },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'patient', component: PatientComponent, canActivate: [AuthorizeGuard] },
      { path: 'patient/profile/:patientId', component: PatientProfileComponent, canActivate: [AuthorizeGuard] },      
      { path: 'eprescriber', component: EprescriberComponent, canActivate: [AuthorizeGuard] },
      { path: 'eprescriber/:status', component: EprescriberComponent, canActivate: [AuthorizeGuard] },
      { path: 'eprescriber/approval/:id', component: EprescriberApprovalComponent, canActivate: [AuthorizeGuard] },
      { path: 'eprescriber/create/:pharmacyId/:patientId', component: EprescriberCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'eprescriber/create/:pharmacyId/:patientId/:eprescriberTypeId', component: EprescriberCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'eprescriber/create/:pharmacyId/:patientId/:eprescriberTypeId/:eprescriberId', component: EprescriberCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'prescription-review', component: PrescriptionReviewComponent, canActivate: [AuthorizeGuard] },
      { path: 'prescription-review/review/:patientId/:id', component: PrescriptionReviewReviewComponent, canActivate: [AuthorizeGuard] },
      { path: 'incident-report', component: IncidentReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'incident-report/create', component: IncidentReportCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'incident-report/review', component: IncidentReportReviewComponent, canActivate: [AuthorizeGuard] },
      { path: 'incident-report/review/:id', component: IncidentReportReviewComponent, canActivate: [AuthorizeGuard] },
      { path: 'report/report-incident-report', component: IncidentReportStatisticsComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/facility', component: SettingsFacilityComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/users', component: SettingsUsersComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/users/create', component: SettingsUserCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/users/profile', component: SettingsUserProfileComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/users/create/:id', component: SettingsUserCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/drugs', component: DrugComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/doctors', component: SettingsDoctorComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/doctors/edit', component: SettingsDoctorCreateComponent, canActivate: [AuthorizeGuard] },     
      { path: 'shipping', component: ShippingComponent, canActivate: [AuthorizeGuard] },
      { path: 'shipping/create', component: ShippingCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'receiving', component: ReceivingComponent, canActivate: [AuthorizeGuard] },
      { path: 'reorder', component: ReorderComponent, canActivate: [AuthorizeGuard] },
      { path: 'reorder/create', component: ReorderCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'reorder/create/:type', component: ReorderCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'reorder/view', component: ReorderViewComponent, canActivate: [AuthorizeGuard] },
      { path: 'reorder/view/:id', component: ReorderViewComponent, canActivate: [AuthorizeGuard] },
      { path: 'report/statistics', component: StatisticsComponent, canActivate: [AuthorizeGuard] },
      { path: 'report/drug-record-book', component: DrugRecordBookComponent, canActivate: [AuthorizeGuard] },
      { path: 'report/additional-reports', component: AdditionalReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'pos/reports/sales-payment', component: SalesPaymentComponent, canActivate: [AuthorizeGuard] },
      { path: 'medscheck/annual/:medsCheckId', component: AnnualComponent, canActivate: [AuthorizeGuard] },
      { path: 'medscheck/annual-ltc/:pharmacyId/:patientId', component: AnnualLtcComponent, canActivate: [AuthorizeGuard] },
      { path: 'medscheck/annual-home/:pharmacyId/:patientId', component: AnnualHomeComponent, canActivate: [AuthorizeGuard] },
      { path: 'medscheck/annual-diabetes/:medsCheckId', component: AnnualDiabetesComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation', component: NarcoticReconciliationComponent, canActivate: [AuthorizeGuard] },
      { path: 'initial-count', component: InitialCountComponent, canActivate: [AuthorizeGuard] },
      { path: 'initial-count/:id', component: InitialCountComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation-item', component: NarcoticReconciliationItemComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation-item/:id', component: NarcoticReconciliationItemComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation-item-detail', component: NarcoticReconciliationItemDetailComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation-item-detail/:narcoticReconciliationItemId', component: NarcoticReconciliationItemDetailComponent, canActivate: [AuthorizeGuard] },
      { path: 'narcotic-reconciliation-item-detail/:narcoticReconciliationId/:narcoticReconciliationItemId', component: NarcoticReconciliationItemDetailComponent, canActivate: [AuthorizeGuard] },
      { path: 'purchase-item/:id', component: NarcoticReconciliationPurchaseItemComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/training', component: SettingsTrainingComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/training/classroom/create', component: SettingsTrainingCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'settings/training/classroom/update/:trainingId', component: SettingsTrainingCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'training', component: UserTrainingComponent, canActivate: [AuthorizeGuard] },
      { path: 'training/:trainingId/:userTrainingId', component: UserTrainingItemComponent, canActivate: [AuthorizeGuard] },
      { path: 'assessment/:userTrainingAssessmentId', component: UserTrainingAssessmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'report/training', component: ReportTrainingComponent, canActivate: [AuthorizeGuard] },
      { path: 'pos/token', component: PosTokenComponent, canActivate: [AuthorizeGuard] },
      { path: 'pos/downloads/backup', component: PosBackupComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/videos', component: FaqVideosComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/forms', component: FaqFormsComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/policy-procedure', component: FaqPolicyProcedureComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/audits', component: FaqAuditsComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/educational', component: EducationalsComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/info', component: InfoComponent, canActivate: [AuthorizeGuard] },
      { path: 'faq/solvoIt', component: SolvoItComponent, canActivate: [AuthorizeGuard] },
      { path: 'invoice/view', component: InvoiceViewComponent, canActivate: [AuthorizeGuard] },
      { path: 'invoice/create', component: InvoiceCreateComponent, canActivate: [AuthorizeGuard] },
      { path: 'invoice/patient-list', component: InvoicePatientListComponent, canActivate: [AuthorizeGuard] },
      { path: 'patient/emergency-pharmacy/:pharmacyId/:patientId/:eprescriberTypeId', component: EmergencyPharmacyComponent, canActivate: [AuthorizeGuard] }      
    ])
  ],
  providers: [
    SharedService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
